import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import { matchPath } from 'react-router';
import RouteTemplates from '../../routeTemplates';
import { APPOINTMENT_BOOK_NEW_PATIENT_SUCCESS, GUIDED_RESPONSE_CONTINUE_FLOW_SUCCESS, GUIDED_RESPONSE_PREVIOUS_SUCCESS, GUIDED_RESPONSE_START_FLOW_SUCCESS } from '../../actions/actionTypes';
import { IconDefinition, faCalendarCheck, faClipboard, faUser } from '@fortawesome/free-regular-svg-icons';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';

const initialState = {
    activeWorkflowName: null as WorkflowName | null,
    activeStepIndex: 0,
    workflowStepsSubSteps: {} as WorkflowStepsSubSteps,
};

const workFlowSlice = createSlice({
    name: 'workflow',
    initialState,
    reducers: {
        startWorkflow: (state, action: PayloadAction<WorkflowName>) => {
            state.activeWorkflowName = action.payload;
            state.activeStepIndex = 0;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(LOCATION_CHANGE, (state, action: LocationChangeAction) => {
                if (state.activeWorkflowName !== null) {
                    const indexOfStepThatMatchesPath = findIndexOfStepThatMatchesPath(action.payload.location.pathname, workflows[state.activeWorkflowName]);

                    if (indexOfStepThatMatchesPath >= 0) {
                        state.activeStepIndex = indexOfStepThatMatchesPath;
                    }
                }
            })
            .addCase(GUIDED_RESPONSE_START_FLOW_SUCCESS, (state, action) => {
                if (isDecisionSupportTheActiveStep(state.activeStepIndex, state.activeWorkflowName)) {
                    state.workflowStepsSubSteps[state.activeStepIndex] = { currentStep: 0, totalSteps: 3 };
                }
            })
            .addCase(GUIDED_RESPONSE_CONTINUE_FLOW_SUCCESS, (state, action) => {
                if (isDecisionSupportTheActiveStep(state.activeStepIndex, state.activeWorkflowName)) {
                    const subSteps = state.workflowStepsSubSteps[state.activeStepIndex];
                    subSteps.currentStep++;
                    subSteps.totalSteps++;
                }
            })
            .addCase(GUIDED_RESPONSE_PREVIOUS_SUCCESS, (state, action) => {
                if (isDecisionSupportTheActiveStep(state.activeStepIndex, state.activeWorkflowName)) {
                    const subSteps = state.workflowStepsSubSteps[state.activeStepIndex];
                    subSteps.currentStep--;
                    subSteps.totalSteps--;
                }
            })
            .addCase(APPOINTMENT_BOOK_NEW_PATIENT_SUCCESS, (state, action) => {
                state.activeStepIndex = 0;
                state.activeWorkflowName = null;
                state.workflowStepsSubSteps = {};
            })
    },
});
export const {
    startWorkflow,
} = workFlowSlice.actions;

export const workflows: Workflows = {
    withDecisionSupport: [
        'decisionSupport',
        'chooseAppointment',
        'bookAppointment',
        'appointmentDetails',
    ],
    noDecisionSupport: [
        'chooseAppointment',
        'bookAppointment',
        'appointmentDetails',
    ],
    bookAnotherAppointment: [
        'bookAnotherAppointment',
        'chooseAppointment',
        'bookAppointment',
        'appointmentDetails',
    ],
}

export const workflowSteps: WorkflowSteps = [
    {
        name: 'decisionSupport',
        label: "Questions",
        routeTemplate: RouteTemplates.decisionSupport,
        routeTemplateWithRoutePrefix: RouteTemplates.decisionSupportWithRoutePrefix,
        icon: faClipboard,
    },
    {
        name: 'bookAnotherAppointment',
        label: "Questions",
        routeTemplate: RouteTemplates.bookAnotherAppointment,
        routeTemplateWithRoutePrefix: RouteTemplates.bookAnotherAppointmentWithRoutePrefix,
        icon: faClipboard,
    },
    {
        name: 'chooseAppointment',
        label: "Choose an Appointment",
        routeTemplate: RouteTemplates.availability,
        routeTemplateWithRoutePrefix: RouteTemplates.availabilityWithRoutePrefix,
        icon: faCalendarDays,
    },
    {
        name: 'bookAppointment',
        label: "Patient Details",
        routeTemplate: RouteTemplates.bookAppointment,
        routeTemplateWithRoutePrefix: RouteTemplates.bookAppointmentWithRoutePrefix,
        icon: faUser,
    },
    {
        name: 'appointmentDetails',
        label: "Booking Complete",
        routeTemplate: RouteTemplates.appointmentDetails,
        routeTemplateWithRoutePrefix: RouteTemplates.appointmentDetailsWithRoutePrefix,
        icon: faCalendarCheck,
    },
]

export type WorkflowName = 'withDecisionSupport' | 'noDecisionSupport' | 'bookAnotherAppointment';

export type WorkflowStepName = 'decisionSupport' | 'chooseAppointment' | 'bookAppointment' | 'appointmentDetails' | 'bookAnotherAppointment';

type Workflows = {
    [key in WorkflowName]: WorkflowStepNames
}

type WorkflowStepNames = WorkflowStepName[];

type WorkflowSteps = WorkflowStep[];

export interface WorkflowStep {
    name: WorkflowStepName,
    label: string,
    routeTemplate: string,
    routeTemplateWithRoutePrefix: string,
    icon: IconDefinition,
}

interface WorkflowStepsSubSteps {
    [key: number]: WorkflowStepSubSteps,
}

export interface WorkflowStepSubSteps {
    currentStep: number,
    totalSteps: number,
}

function isRouteAMatch(pathname: string, routeTemplate: string): boolean {
    return !!matchPath(pathname, {
        path: routeTemplate,
        exact: true,
        strict: false
    })
}

function findIndexOfStepThatMatchesPath(pathname: string, stepNames: string[]) {
    return stepNames.findIndex(stepName => {
        const step = workflowSteps.find(step => step.name === stepName);
        if (step === null || step === undefined) {
            return false
        } else {
            return (
                isRouteAMatch(pathname, step.routeTemplate)
                ||
                isRouteAMatch(pathname, step.routeTemplateWithRoutePrefix)
            )
        }
    });
};

function isDecisionSupportTheActiveStep(activeStepIndex: number, activeWorkflowName: WorkflowName | null): boolean {
    if (activeWorkflowName === null) return false;
    return workflows[activeWorkflowName].findIndex(name => (name === 'decisionSupport')) === activeStepIndex
        || workflows[activeWorkflowName].findIndex(name => (name === 'bookAnotherAppointment')) === activeStepIndex
}

export default workFlowSlice.reducer;
