import React from 'react';

export function PanelWithMobileCollapse(props) {
	const { alwaysVisibleContent, conditionalContent, handleToggleCollapse, name } = props;
	const conditionalClassName = props.conditionalClassName ? props.conditionalClassName : 'doesCollapse';
	const dragHandleClassName = props.dragHandleClassName ? props.dragHandleClassName : 'drag';
	const sectionClassName = props.sectionClassName ? props.sectionClassName : '';
	return (
		<section className={sectionClassName} onClick={() => handleToggleCollapse(name)}>
			<button className={dragHandleClassName}></button>
			{alwaysVisibleContent}
			<div className={conditionalClassName}>{conditionalContent}</div>
		</section>
	);
}
