//3rd party
import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

//actions
import { clearAllErrors } from '../../actions/errorActions';
import { getAppointmentById, cancelAppointment } from '../../actions/appointmentActions';
import { clearValidEntryPoint } from '../../actions/sessionActions';

//lib
import { isEmpty } from '../../lib/misc';
import { validateEmail } from '../../lib/validation';

//comps
import * as Icon from '../../components/icons';
import * as Button from '../../components/buttons';
import * as FieldControl from '../../components/input';
import ActiveErrors from '../../components/misc/activeErrors';
import { DisclaimerHtml } from '../../components/misc/miscComponents';
import { LoadingIndicatorModal } from '../../components/loadingIndicator/loadingIndicatorModal';

export const AppointmentCancelView = (props) => {
	/* eslint-disable */
	const startUp = useEffect(() => {
		getAppointmentDetails(props.match?.params?.referenceId);
	}, []);

	const getAppointmentDetails = (referenceId) => {
		props.getAppointmentById(referenceId);
	};

	const cancelAppointment = (e) => {
		e.preventDefault();
		props.dispatch(clearValidEntryPoint);
		props.clearAllErrors();
		if (preCancelValidate()) {
			let cancelRequest = {
				reasonForCancellation: reasonForCancellationText,
				sendCancellationEmailToPatient: patientCancellationEmailConsent && props.details.patientEmail ? true : false,
				email: patientEmailOther ? patientEmailOther : props.details.patientEmail,
			};
			props.cancelAppointment(props.token, props.match?.params?.referenceId, cancelRequest);
		}
	};

	const preCancelValidate = () => {
		let isValid = true;
		if (props.showReasonForCancellationInputs) {
			if (!reasonForCancellationText) {
				setReasonForCancellationError({ message: '* reason for cancellation is required.' });
				isValid = false;
			} else if (reasonForCancellationText.length > 200) {
				setReasonForCancellationError({ message: '* reason for cancellation max length is 200 characters.' });
				isValid = false;
			}
		}
		return isValid;
	};

	const [patientCancellationEmailConsent, setPatientCancellationEmailConsent] = useState(
		props.emailCancellationEnabled && !props.showPatientCancellationEmailOptIn,
	);
	const [patientEmailOther, setPatientEmailOther] = useState('');
	const [patientEmailOtherError, setPatientEmailOtherError] = useState();
	const [reasonForCancellationText, setReasonForCancellationText] = useState('');
	const [reasonForCancellationError, setReasonForCancellationError] = useState();

	const [cancedlEmailOtherFocused, setCancelEmailOtherFocused] = useState(false);
	const [reasonForCancelFocused, setReasonForCancelFocused] = useState(false);
	setCancelEmailOtherFocused;

	return (
		<div className="view-content appointment-cancel-view">
			{(props.isLoading || props.isCancellingAppointment) && <LoadingIndicatorModal />}
			<ActiveErrors />
			<main className={'main-content'}>
				{!props.isLoading && (
					<>
						<div className="main-content-header">
							<Icon.IconCalendarTimes />
							<h2>{props.headerText}</h2>
						</div>
						<div className="content-section">
							{(props.appointmentStatus === appointmentCancelStates.PENDING_CONFIRM ||
								(props.appointmentStatus === appointmentCancelStates.CANCELLED &&
									props.details?.servicePostCancellationText)) && (
									<div
										dangerouslySetInnerHTML={{
											__html: props.details.isCancelled
												? props.details.servicePostCancellationText
												: props.details.serviceCancellationText ||
												'Please click the "Cancel Appointment" button to complete the cancellation process.',
										}}
									/>
								)}
						</div>
						{props.appointmentStatus === appointmentCancelStates.PENDING_CONFIRM && (
							<>
								{props.showReasonForCancellationInputs && (
									<>
										<FieldControl.TextInput
											id="cancelReason"
											name="cancelReason"
											maxLength={200}
											error={reasonForCancellationError}
											value={reasonForCancellationText}
											isFocused={reasonForCancelFocused}
											onBlur={(e) => {
												setReasonForCancelFocused(false);
											}}
											onFocus={(e) => {
												setReasonForCancelFocused(true);
											}}
											onChange={(e) => {
												setReasonForCancellationText(e?.target?.value);
												setReasonForCancellationError(null);
											}}
											isVisible={!props.enableCustomCancelReasons}
											label="Reason For Cancellation"
										/>
										<FieldControl.DropdownSelect
											id={'cancelReason'}
											name="cancelReason"
											error={reasonForCancellationError}
											value={reasonForCancellationText}
											isVisible={props.enableCustomCancelReasons}
											label="Reason For Cancellation"
											onChange={(e) => {
												e.preventDefault();
												setReasonForCancellationText(e?.target?.value);
												setReasonForCancellationError(null);
											}}
											optionList={props.systemCancelReasons}
										/>
										<br />
									</>
								)}
								{props.emailCancellationEnabled && (
									<div className="section-row">
										{props.showPatientCancellationEmailOptIn && (
											<div className="subsection-row">
												<FieldControl.CheckboxInput
													name="emailConfirmationConsent"
													value={patientCancellationEmailConsent}
													onChange={() => {
														setPatientCancellationEmailConsent((x) => !x);
													}}
													label="Receive Cancellation Email"
													isVisible={true}
												/>
												<br />
												{patientCancellationEmailConsent && props.allowEmailCancellationOtherEmail && (
													<FieldControl.TextInput
														id="patientEmailOther"
														name="patientEmailOther"
														maxLength={200}
														error={patientEmailOtherError}
														value={patientEmailOther}
														isFocused={cancedlEmailOtherFocused}
														onBlur={(e) => setCancelEmailOtherFocused(false)}
														onFocus={(e) => setCancelEmailOtherFocused(true)}
														onChange={(e) => {
															setPatientEmailOther(e?.target?.value);
															setPatientEmailOtherError(
																validateEmail(e?.target?.value)
																	? null
																	: { message: '* please provide valid email address.' },
															);
														}}
														isVisible={props.allowEmailCancellationOtherEmail ? true : false}
														label="Other Email Address"
													/>
												)}
											</div>
										)}
										<div className="subsection-row">
											<DisclaimerHtml
												isVisible={props.showEmailCancellationDisclaimer}
												text={props.patientCancellationEmailDisclaimer}
												className="disclaimer"
											/>
											<DisclaimerHtml
												isVisible={props.showAutoOptInEmailCancellationInstructions}
												text={props.cancellationEmailInstructions}
												className="disclaimer confirmation-email-instructions"
											/>
										</div>
									</div>
								)}
								<div className="section-row container-btn">
									<Button.ButtonSecondary
										className="cancel-appointment-btn"
										onClick={cancelAppointment}
										disabled={props.isCancellingAppointment}
									>
										<span>Cancel Appointment</span>
									</Button.ButtonSecondary>
								</div>
							</>
						)}
					</>
				)}
			</main>
		</div>
	);
};

function mapStateToProps(state, ownProps) {
	const isLoading = state.ajaxCallsInProgress > 0;
	const appointmentStatus = getAppointmentStatus(state.appointment.details, isLoading);

	return {
		allowEmailCancellationOtherEmail: state.config.scheduling.notification.allowEmailCancellationOtherEmail,
		appointmentStatus,
		cancellationEmailInstructions:
			'<p>* You will automatically receive an email confirmation upon cancelling this appointment.</p>',
		details: state.appointment.details,
		emailCancellationEnabled: state.config.scheduling.notification.emailCancellationEnabled,
		enableCustomCancelReasons: state.config.scheduling.enableCustomCancelReasons,
		headerText: headerTexts[appointmentStatus],
		isAppointmentCancelled: state.appointment?.details?.isCancelled || false,
		isCancellingAppointment: state.appointment.isLoading.cancelAppointment,
		isGroupNumberActive: state.config.system.isGroupNumberActive,
		isGroupNumberRequired: state.config.system.isGroupNumberRequired,
		isLoading: isLoading,
		isMemberIdActive: state.config.system.isMemberIdActive,
		isMemberIdRequired: state.config.system.isMemberIdRequired,
		labels: state.config.clientBranding,
		memberIdFieldLabel: state.config.system.memberIdFieldLabel,
		patientCancellationEmailDisclaimer: state.config.scheduling.notification.patientCancellationEmailDisclaimer,
		providerFieldConfig: state.config.provider,
		reservation: state.appointment.reservation,
		schedulingConfig: state.config.scheduling,
		showAutoOptInEmailCancellationInstructions:
			state.config.scheduling.notification.emailCancellationEnabled &&
			!state.config.scheduling.notification.showPatientCancellationEmailOptIn,
		showEmailCancellationDisclaimer:
			state.config.scheduling.notification.patientCancellationEmailDisclaimer &&
			state.config.scheduling.notification.patientCancellationEmailDisclaimer.length > 0,
		showInsuranceOnAppointmentDetails: state.config.scheduling.showInsuranceOnAppointmentDetails,
		showInsuranceOnBookAppointment: state.config.scheduling.showInsuranceOnBookAppointment,
		showPatientCancellationEmailOptIn: state.config.scheduling.notification.showPatientCancellationEmailOptIn,
		showReasonForCancellationInputs: state.config.scheduling.showReasonForCancellation,
		showReasonForVisitApptDetails: state.config.scheduling.showReasonForVisitApptDetails,
		systemCancelReasons: state.config.systemCancelReasons.map((x) => {
			return { id: x.name, name: x.name };
		}),
		token: state.auth.token,
		useDecisionSupport: state.config.decisionSupport.useDecisionSupport,
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		...bindActionCreators(
			{
				clearAllErrors,
				getAppointmentById,
				cancelAppointment,
			},
			dispatch,
		),
		dispatch,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentCancelView);

const appointmentCancelStates = {
	CANCELLED: 'cancelled',
	IN_THE_PAST: 'in_the_past',
	PENDING_CONFIRM: 'pending_confirm',
	APPOINTMENT_NOT_FOUND: 'appointment_not_found',
};

const headerTexts = {
	[appointmentCancelStates.CANCELLED]: 'Your appointment has been canceled',
	[appointmentCancelStates.IN_THE_PAST]: 'Your appointment is in the past and cannot be canceled.',
	[appointmentCancelStates.PENDING_CONFIRM]: 'Almost done...',
	[appointmentCancelStates.APPOINTMENT_NOT_FOUND]: 'Appointment not found',
};

const getAppointmentStatus = (appointmentDetails, isLoading) => {
	if (!isEmpty(appointmentDetails)) {
		if (appointmentDetails.isCancelled) {
			return appointmentCancelStates.CANCELLED;
		} else if (appointmentDetails.isAppointmentInPast) {
			return appointmentCancelStates.IN_THE_PAST;
		} else {
			return appointmentCancelStates.PENDING_CONFIRM;
		}
	} else if (!isLoading) {
		return appointmentCancelStates.APPOINTMENT_NOT_FOUND;
	}
};
