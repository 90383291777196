import React from 'react';
import PropTypes from 'prop-types';
import './conditionsOfAccessSection.css';

export default function ConditionsOfAccessSection(props) {
	const { onChange, name, onLabelClick, value, showWarning, isMobile } = props;
	const className = isMobile ? "content-section-no-margin" : "content-section-bottom-margin"

	return (
		<section id="conditionsOfAccess" className={className}>
			<div className="section-row conditions-of-access-section">
				{ showWarning &&
					<div className="conditionsOfAccessWarning">
						* Accepting conditions of access is required.
					</div>
				}
				<div className="field-control checkbox xl">
					<input
						checked={value}
						className="input-checkbox"
						id="conditionsOfAccessInput"
						name={name}
						onChange={onChange}
						type="checkbox"
						value={value}
					/>
					<label id="conditionsOfAccessField" htmlFor="conditionsOfAccessInput" className="field-label">
						<div>
							<span>
								<strong>I agree to the <a href="/conditions-of-access" onClick={onLabelClick}>Conditions of Access</a> prior to booking my appointment.</strong>
							</span>
						</div>
						<div>
							<span className="conditions-of-access-required">* required</span>
						</div>
					</label>
				</div>
			</div>
		</section>
	);
};

ConditionsOfAccessSection.propTypes = {
	onChange: PropTypes.func,
	name: PropTypes.string,
	value: PropTypes.bool,
	onLabelClick: PropTypes.func,
}
