import React from 'react';
import classNames from 'classnames';
import { AppointmentDateTime } from './appointmentDateTime';
import { ProviderNameAndSpecialty } from '../provider/providerNameAndSpecialty';
import { ProviderSiteAddressAndPhone } from '../provider/providerSiteAddressAndPhone';
import { PanelWithMobileCollapse } from '../misc/panelWithMobileCollapse';
import { ReasonForVisitDetails } from '../misc/reasonForVisitDetails';
import * as Button from '../buttons';
import CountDownTimer from '../../components/misc/countDownTimer'

export default function AppointmentSummaryPanel(props) {
	const {
		appointment,
		disableButtons,
		getCalendar,
		getDirections,
		handleToggleCollapse,
		isCollapsed,
		isLoading,
		isMobile,
		mode,
		name,
		onCancelAppointmentClick,
		onChangeAppointmentClick,
		providerFieldConfig,
		summaryConfig,
		fieldConfig,
		handleBookAnother,
		endedInGuidedResponse,
		handleReservationExpired,
	} = props;

	const isReservation = mode === 'Reservation';
	const isBookedAppointment = mode === 'BookedAppointment';

	const [isExpiredReservation, setIsExpiredReservation] = React.useState(false);

	const shouldShowProviderAddress = !appointment.suppressAddress;
	const shouldShowProviderPhone =
		providerFieldConfig.phone && providerFieldConfig.phone.isVisible && !appointment.suppressPhoneNumber;
	const providerPhoneLabel =
		providerFieldConfig.phone && providerFieldConfig.phone.fieldLabel ? providerFieldConfig.phone.fieldLabel : 'Phone:';

	const shouldShowReasonForVisit =
		fieldConfig.appointmentFields.reasonForVisit && fieldConfig.appointmentFields.reasonForVisit.isVisible;
	const reasonForVisitLabel =
		fieldConfig.appointmentFields.reasonForVisit && fieldConfig.appointmentFields.reasonForVisit.fieldLabel
			? fieldConfig.appointmentFields.reasonForVisit.fieldLabel
			: 'Reason for Visit';

	const sectionClassName = classNames(name, { collapsed: isCollapsed });

	let showArrivalTime =
		appointment.expectedArrivalTime && appointment.expectedArrivalTime !== appointment.providerAppointmentDateTime;


	const reservationExpired = () => {
		setIsExpiredReservation(true);
		handleReservationExpired();
	}

	let alwaysVisibleContent = (
		<>
			<h2 className="tag-appointment-summary-header">Your Appointment Summary</h2>
			{!isLoading && (
				<>
					<AppointmentDateTime
						appointmentDateTime={appointment.providerAppointmentDateTime}
						arrivalTime={appointment.expectedArrivalTime}
						showArrivalTime={showArrivalTime}
					/>
					{isMobile && isReservation &&
						<div style={{marginBottom: "0.5em"}}>
							{!isExpiredReservation ? (
								<div className="reservationCountDown">
									Reservation will expire in <span><CountDownTimer countDownExpirationTime={props.reservationExpirationTime} countDownExpired={reservationExpired} /></span>
								</div>
							) : (
								<div className="reservationCountDown">
									Reservation expired, Please <span className="link" onClick={(e) => onChangeAppointmentClick(e)}>pick a slot again.</span>
								</div>
							)}
						</div>
					}
				</>
			)}
		</>
	);

	let conditionalContent = (
		<>
			{!isLoading && (
				<>
					<ProviderNameAndSpecialty
						providerFirstName={appointment.providerFirstName}
						providerLastName={appointment.providerLastName}
						assetTag={appointment.assetTag}
						providerProfessionalDesignation={appointment.providerProfessionalDesignation}
						providerSpecialty={appointment.providerSpecialty}
					/>
					<ProviderSiteAddressAndPhone
						providerSiteDisplayName={appointment.providerSiteDisplayName}
						providerAddressLine1={appointment.providerAddressLine1}
						providerAddressLine2={appointment.providerAddressLine2}
						city={appointment.providerCity}
						state={appointment.providerState}
						zipCode={appointment.providerZip}
						showAddress={shouldShowProviderAddress}
						showPhone={shouldShowProviderPhone}
						phoneLabel={providerPhoneLabel}
						phoneNumber={appointment.providerPhoneNumber}
					/>
					{shouldShowReasonForVisit && summaryConfig && summaryConfig.showReasonForVisit && (
						<ReasonForVisitDetails label={reasonForVisitLabel} reasonForVisit={appointment.reasonForVisit} />
					)}
					{isReservation && summaryConfig && summaryConfig.allowChangeAppointment && (
						<>
							<Button.ButtonSecondary
								className="change-appointment button-secondary"
								disabled={disableButtons}
								onClick={(e) => onChangeAppointmentClick(e)}
								type={'button'}
							>
								Change Date &amp; Time
							</Button.ButtonSecondary>
							<br />
							{!isMobile &&
								<div>
									{!isExpiredReservation ? (
										<div className="reservationCountDown">
											Reservation will expire in <span><CountDownTimer countDownExpirationTime={props.reservationExpirationTime} countDownExpired={reservationExpired} /></span>
										</div>
									) : (
										<div className="reservationCountDown">
											Reservation expired, Please <span className="link" onClick={(e) => onChangeAppointmentClick(e)}>pick a slot again.</span>
										</div>
									)}
								</div>
							}
						</>
					)}
					{isBookedAppointment && summaryConfig && summaryConfig.enableCancelAppointment && (
						<Button.ButtonSecondary
							className={'cancel-appointment button-secondary'}
							disabled={disableButtons}
							onClick={(e) => onCancelAppointmentClick(e)}
							type={'button'}
						>
							Cancel Appointment
						</Button.ButtonSecondary>
					)}
					{isBookedAppointment && isMobile && endedInGuidedResponse && summaryConfig && summaryConfig.allowBookAnotherAppointment && (
						<Button.ButtonSecondary
							className={'button-secondary'}
							disabled={disableButtons}
							onClick={handleBookAnother}
							type={'button'}
						>
							Book Another Appointment
						</Button.ButtonSecondary>
					)}
					{isBookedAppointment && isMobile && summaryConfig && summaryConfig.allowIcsFileDownload && (
						<Button.ButtonSecondary
							className={'add-to-calendar-btn'}
							disabled={disableButtons}
							onClick={getCalendar}
							type={'button'}
						>
							Add to Calendar
						</Button.ButtonSecondary>
					)}
					{isBookedAppointment && isMobile && summaryConfig && summaryConfig.allowGetDirections && (
						<Button.ButtonSecondary
							className="get-directions-btn"
							disabled={disableButtons}
							onClick={getDirections}
							type={'button'}
						>
							Get Direction
						</Button.ButtonSecondary>
					)}
				</>
			)}
		</>
	);

	return (
		<PanelWithMobileCollapse
			alwaysVisibleContent={alwaysVisibleContent}
			conditionalContent={conditionalContent}
			handleToggleCollapse={handleToggleCollapse}
			name={name}
			sectionClassName={sectionClassName}
		/>
	);
}
