import { useState } from 'react';
import * as FieldControl from '../input';
import { CollapsablePanelWithSectionHeader, DisclaimerHtml } from './miscComponents';
import AsyncSelect from 'react-select/async';
import '../../css/views/patientDetailsSection.css';

const emptyAddressOption = {
	label: "Select...", value: {}
};
export default function PatientDetailsSection(props) {
	const {
		enableSectionValidationIcons,
		sectionConfig,
		patientFieldsInfo,
		handleInputChange,
		stateList,
		isCollapsed,
		handleToggleCollapse,
		handleInputFocus,
		handleInputBlur,
		hasErrors,
		name,
		shouldShowSectionValidation,
		genderList,
		showAddressSearch,
		loadAddressOptions,
		handleAddressChange,
		selectedAddressOption,
		isAddressLoading
	} = props;
	const [isManualAddressEntry, setIsManualAddressEntry] = useState(!showAddressSearch); 

	const handleIsManualAddressEntryChange = (e) => {
		handleAddressChange(emptyAddressOption);
		setIsManualAddressEntry(e);
	}

	return (
		<CollapsablePanelWithSectionHeader
			className="main-section"
			enableSectionValidationIcons={enableSectionValidationIcons}
			label={sectionConfig.headerText}
			isCollapsed={isCollapsed}
			isCollapsable={sectionConfig.isCollapsable}
			onToggleCollapse={handleToggleCollapse}
			name={name}
			hasErrors={hasErrors}
			showHeader={sectionConfig.showHeader}
			shouldShowSectionValidation={shouldShowSectionValidation}
		>
			<div className="section-body">
				<div className="section-row">
					<DisclaimerHtml
						className="form-instructions"
						isVisible={!!sectionConfig.patientDetailsConfigInstructions}
						text={sectionConfig.patientDetailsConfigInstructions}
					/>
				</div>
				<div className="section-row">
					<FieldControl.TextInput
						{...patientFieldsInfo.memberId}
						onChange={handleInputChange}
						onFocus={handleInputFocus}
						onBlur={handleInputBlur}
					/>
					<FieldControl.TextInput
						{...patientFieldsInfo.groupNumber}
						onChange={handleInputChange}
						onFocus={handleInputFocus}
						onBlur={handleInputBlur}
					/>
				</div>
				<div className="section-row">
					<FieldControl.TextInput
						{...patientFieldsInfo.firstName}
						onChange={handleInputChange}
						onFocus={handleInputFocus}
						onBlur={handleInputBlur}
					/>
					<FieldControl.TextInput
						{...patientFieldsInfo.middleName}
						onChange={handleInputChange}
						onFocus={handleInputFocus}
						onBlur={handleInputBlur}
					/>
					<FieldControl.TextInput
						{...patientFieldsInfo.lastName}
						onChange={handleInputChange}
						onFocus={handleInputFocus}
						onBlur={handleInputBlur}
					/>
				</div>
				{showAddressSearch &&
					<div>
						<div>
							<label className="address-search-label">{isAddressLoading ? "Loading..." : "Address"}</label>
						</div>
						<AsyncSelect
							className="address-search-select"
							cacheOptions
							defaultOptions={[emptyAddressOption]}
							loadOptions={loadAddressOptions}
							onChange={handleAddressChange}
							value={selectedAddressOption}
							isDisabled={isManualAddressEntry || isAddressLoading}
							styles={{
								control: (baseStyles, _) => ({
									...baseStyles,
									borderColor: 'white',
									borderBottomColor: '#eae6e6'
								}),
							}}
						/>
						<AddressCheckbox
							isManualEntry={isManualAddressEntry}
							onChange={handleIsManualAddressEntryChange}
							disabled={isAddressLoading}
						/>
					</div>
				}
				<div className="section-row">
					<FieldControl.TextInput
						{...patientFieldsInfo.addressLine1}
						onChange={handleInputChange}
						onFocus={handleInputFocus}
						onBlur={handleInputBlur}
						size={'lg'}
						disabled={!isManualAddressEntry}
					/>
				</div>
				<div className="section-row">
					<FieldControl.TextInput
						{...patientFieldsInfo.addressLine2}
						onChange={handleInputChange}
						onFocus={handleInputFocus}
						onBlur={handleInputBlur}
						size={'lg'}
						disabled={!isManualAddressEntry}
					/>
				</div>
				<div className="section-row">
					<FieldControl.TextInput
						{...patientFieldsInfo.cityName}
						onChange={handleInputChange}
						onFocus={handleInputFocus}
						onBlur={handleInputBlur}
						disabled={!isManualAddressEntry}
					/>
					<FieldControl.DropdownSelect
						{...patientFieldsInfo.stateId}
						onChange={handleInputChange}
						onFocus={handleInputFocus}
						onBlur={handleInputBlur}
						optionList={stateList}
						disabled={!isManualAddressEntry}
					/>
					<FieldControl.ZipInput
						{...patientFieldsInfo.zipCode}
						onChange={handleInputChange}
						onFocus={handleInputFocus}
						onBlur={handleInputBlur}
						disabled={!isManualAddressEntry}
					/>
				</div>
				<div className="section-row">
					<FieldControl.GenderSelections
						{...patientFieldsInfo.gender}
						onChange={handleInputChange}
						onFocus={handleInputFocus}
						onBlur={handleInputBlur}
						optionList={genderList}
					/>
					<FieldControl.DateInput
						{...patientFieldsInfo.dateOfBirth}
						onChange={handleInputChange}
						onFocus={handleInputFocus}
						onBlur={handleInputBlur}
					/>
					<FieldControl.TextInput
						{...patientFieldsInfo.parentOrGuardianName}
						onChange={handleInputChange}
						onFocus={handleInputFocus}
						onBlur={handleInputBlur}
					/>
				</div>
			</div>
		</CollapsablePanelWithSectionHeader>
	);
}

const AddressCheckbox = ({ onChange, isManualEntry, disabled }) => {
	return (
		<div className="address-search-checkbox">
			<label htmlFor="address-checkbox">Enter Address Manually?</label>
			<input
				id="address-checkbox"
				type="checkbox"
				onChange={e => onChange(e.target.checked)}
				value={isManualEntry}
				disabled={disabled}
			/>
		</div>
	)
}