import {
    SortOrder,
} from '../types/localTypes';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from "uuid";
import { DEFAULT_DATE_FORMAT } from './availabilitySlice';

export const initialFilterState = {
    availabilityValidRefId: uuidv4(),
    viewBaseDate: dayjs().format(DEFAULT_DATE_FORMAT),

    zipCode: "",
    radius: "",
    gender: "",
    serviceCategoryId: "",
    payorType: "",
    specialtyId: "",

    sortOrder: SortOrder.FirstAvailability,
    services: [],
    serviceName: "",
    sites: [],
    siteName: "",
    insuranceCarrierId: "",
    insuranceStateId: "",
    language: "",

    daysOfWeek: [],
    minStartTime: "",
    maxStartTime: "",
    appointmentTypeIds: [],
    appointmentTypeModalityIds: [],
}

export const initialState = {
    searchContext: {
        calendarSearchCriteria: {
            productInstanceId: 0,
            correlationKey: "",
            identityCriteria: {
                calendarsByIdentities: [],
                applyCalendarIdentityFilter: true,
                applyCriteriaToPreferredCalendars: false,
                ignoreInvalidCalendars: false,
                practiceSystemIdentities: [],
                siteIdentities: [],
                serviceIdentities: []
            },
            providerCriteria: {
                languageName: "",
                genderCode: "",
                specialtyName: "",
                specialtyId: null,
                serviceProviderType: "",
                serviceProviderTypeId: null
            },
            patientCriteria: {
                patientAge: null,
                isPublicTransportationRequired: null,
                payorType: null,
                insuranceProviderId: null,
            },
            distanceFilter: {
                zipCode: "",
                maxDistanceInMiles: ""
            },
            responseConfig: {
                serviceIdentityType: "",
                siteIdentityType: "",
                calendarIdentityType: "",
                sortOrder: null,
                includeProviderLanguages: true,
                includeAppointmentTypes: true,
                isNullOrEmpty: false
            }
        },
        availabilitySearchCriteria: {
            productInstanceId: 0,
            correlationKey: "",
            returnAllCalendars: false,
            calendarIds: [],
            minStartDate: "",
            maxStartDate: "",
            minStartTime: "",
            maxStartTime: "",
            returnAllDays: true,
            daysOfWeek: [],
            returnAllAppointmentTypes: false,
            appointmentTypeIds: [],
            appointmentTypeNames: [],
            returnAllAppointmentModalities: true,
            appointmentModalityIds: [],
            payorTypeName: null,
            insuranceProviderId: null,
            maxTimeSlotsPerDay: null,
            maxTimeSlots: null,
            patientReferenceId: null,
            memberId: "",
            appointmentModalityId: null,
        }
    },

    calendars: {},
    scanResults: {},
    availability: {},

    sortOrderBaseDate: dayjs().format(DEFAULT_DATE_FORMAT),
    hasSkipAheadToDateBehaviorSettled: false,
    skippedDayCount: 0,

    isSearchingCalendars: false,
    hasDoneFirstCalendarSearch: false,
    inFlightScanLog: {},
    inFlightSearchLog: {},

    pageNumber: 1,
    selectedCalendarId: null,
    selectedCalendarViewBaseDate: null,

    //the initialFilters copy is a reset point
    initialFilters: initialFilterState,
    appliedFilters: initialFilterState,

    initialBatchLog: {
        hasCompleted: false,
        hasNotPreferredBatchCompleted: false,

        hasPreferredBatch: undefined,
        hasPreferredBatchCompleted: false,

        calendarSearchStartedAt: null,
        calendarSearchCompletedAt: null,

        notPreferred: {
            firstScanStartedAt: null,
            firstScanCompletedAt: null,

            searchRequired: null,
            searchStartedAt: null,
            searchCompletedAt: null,
            searchCalendarIds: [],
            searchMinStartDate: null,
            searchMaxStartDate: null,

            nextScanRequired: null,
            nextScanStartedAt: null,
            nextScanCompletedAt: null,
            nextScanCalendarIds: [],
            nextScanMinStartDate: null,
            nextScanMaxStartDate: null,
        },

        preferred: {
            firstScanStartedAt: null,
            firstScanCompletedAt: null,

            searchRequired: null,
            searchStartedAt: null,
            searchCompletedAt: null,
            searchCalendarIds: [],
            searchMinStartDate: null,
            searchMaxStartDate: null,

            nextScanRequired: null,
            nextScanStartedAt: null,
            nextScanCompletedAt: null,
            nextScanCalendarIds: [],
            nextScanMinStartDate: null,
            nextScanMaxStartDate: null,
        },
    }
};